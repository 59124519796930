import { Injectable } from '@angular/core';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {LocalService} from "../../../shared/service/local.service";
import {Router} from "@angular/router";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { AuthEntityService } from './auth-entity.service';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class AuthService  {
  private loggedIn = false
  signupUrl = environment.signupUser;

  get isLoggedIn() {
    this.loggedIn =  !!this.localStore.getData('kmlstat')

    if (!this.loggedIn) {
      this.route.navigate(['/auth']).then(() => {})
    }
    return this.loggedIn;
  }
  constructor(
    private localStore: LocalService,
    private route: Router,
    private http:HttpClient,
    private loginService: AuthEntityService,
    private toastService: HotToastService
    
  ) {

  }

  signupUser(data:any) {
    const loginData = {
      email: data.email,
      password: data.password
    }
    return this.http.post<any>(this.signupUrl, data).pipe(
      map((signupRes: any) => {     
        if(signupRes.status == 'Success'){
          this.toastService.success('User created Successfully!')
          return  this.loginService.add(loginData).subscribe({
            next: (res) => { 
              if(res.status === 'Success') {
                this.toastService.success('Welcome!')
              } else {
                const message = `Something went wrong, ${res.message}`
                this.toastService.error(message)
              }
            },
            error:(err) => { console.log('Login Data Err', err);
            }
          });
        } else {
          console.log('err');
          return 
        }
        
      })
    )
    
  }




}