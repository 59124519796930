import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { HttpOptions } from "@ngrx/data/src/dataservices/interfaces";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
})
export class CashwaqfDataService extends DefaultDataService<any> {
    listCashwaqf = environment.listCashwaqf;

    constructor(
        http:HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private route: Router
    ){
        super('Cashwaqf', http, httpUrlGenerator)
    }

    override getAll(options?: HttpOptions | undefined): Observable<any[]> {
        return this.http.get<any>(this.listCashwaqf).pipe(
            map((cashwaqfs: any) => {
                // console.log('cashwaqf:', cashwaqfs.data[0].institutions );
                return cashwaqfs.data[0].institutions
            })
        )
    }
}