import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, tap } from "rxjs";
import { CashwaqfEntityService } from "../store/cashwaqf/cashwaqf-entity.service";


@Injectable({
    providedIn: "root"
})
export class CashwaqfResolver implements Resolve<boolean> {
    constructor(
        private cashwaqfService: CashwaqfEntityService
    ){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.cashwaqfService.loaded$.pipe(
            tap(loaded => {
                if (!loaded) {
                    this.cashwaqfService.getAll();
                }
            })
        )
    }
}