// const baseUrl = 'http://itqaan.world:80/api/'
const baseUrl = 'http://139.84.233.34:8080/'
const paymentBaseUrl = 'https://139.84.233.34:3000'


export const environment = {
    production: false,

    base: paymentBaseUrl,
    cashWaqfPayment: baseUrl,
    // Auth urls
    loginUser: baseUrl + 'auth/login',
    signupUser: baseUrl + 'auth/register',

    // Cash Waqf
    listCashwaqf: baseUrl + 'cash_waqf',
    listSadaqah: baseUrl + 'cash_sadaqah',
}