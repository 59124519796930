import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DefaultDataService, HttpUrlGenerator} from "@ngrx/data";
import {LoggedInUserInterface, LoginInterface} from "../model/login";
import {BehaviorSubject, map, Observable} from "rxjs";
import {LocalService} from "../../../shared/service/local.service";
import {Route, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthDataService extends DefaultDataService<any> {
  loginUrl = environment.loginUser;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private localStore: LocalService,
    private route: Router
  ) {
    super('Auth', http, httpUrlGenerator)
  }

  override add(loginData: LoggedInUserInterface): Observable<any> {
    return this.http.post<any>(this.loginUrl, loginData).pipe(
      map((loginRes: LoggedInUserInterface) => {     
        loginRes.id = '1'

        if(loginRes.status == 'Success'){
          // Store user details to localstorage
          this.localStore.saveData('itqtk', loginRes.accessToken);
          this.localStore.saveData('itqrtk', loginRes.refreshToken);
          this.localStore.saveData('kmlstat', 'true');
          // Navigate if success
          this.route.navigate(['/']).then(() => {})
          return loginRes
        } else {
          return loginRes
        }
        
      })
    )
  }
}