import { Component } from '@angular/core';
import 'flowbite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'itqaan-world';
  isLoggedIn: boolean = false;

  handleLogin(eventData: any) {
    this.isLoggedIn = !this.isLoggedIn
  }
}
