<div
  class="flex flex-col md:flex-row h-screen w-screen bg-[#F9FAFB] overflow-x-hidden"
>
  <div
    class="hidden bg-headerBlue md:flex flex-col justify-center items-center px-10"
    style="background-image: url('../../../assets/imgs/about-bg.webp')"
  >
    <img src="../../../assets/imgs/about-iphone.webp" alt="Two Iphone" />
    <!-- store icons -->
    <div class="flex gap-4 mb-10">
      <a>
        <img
          ngSrc="../../../../assets/imgs/apple-store.svg"
          alt="GooglePlay Logo"
          class="w-48 h-14"
          width="192"
          height="56"
        />
      </a>
      <a>
        <img
          ngSrc="../../../../assets/imgs/apple-store.svg"
          alt="Apple store Logo"
          class="w-48 h-14"
          width="192"
          height="56"
        />
      </a>
    </div>
  </div>

  <!-- form -->
  <div
    class="px-4 md:px-14 py-10 md:w-[745px] mx-auto h-screen overflow-hidden overflow-y-scroll no-scrollbar"
  >
    <div
      class="flex items-center justify-between md:justify-end md:gap-10 mb-5 md:mb-11"
    >
      <p
        class="text-headerBlue hover:cursor-pointer"
        (click)="isSignUp = !isSignUp"
      >
        New to Itqaan?
      </p>
      <button
        class="w-40 md:w-[100px] py-2 rounded-full text-white bg-headerBlue"
        (click)="isSignUp = !isSignUp"
      >
        {{ isSignUp ? "Sign In" : "Sign Up" }}
      </button>
    </div>

    <!-- the signup form -->
    <form
      *ngIf="isSignUp"
      [formGroup]="signupForm"
      (ngSubmit)="signupUser()"
      class="hiddens bg-white rounded-lg mx-auto px-4 py-8 md:px-14 md:py-16 md:min-h-[800px]"
    >
      <p
        class="text-headerBlue text-3xl font-bold mb-10 text-center md:text-start"
      >
        Create your account
      </p>
      <div>
        <!-- First Name -->
        <div class="mb-6">
          <label for="" class="font-semibold">First Name</label>
          <input
            type="text"
            formControlName="firstName"
            placeholder="Enter First name"
            class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
          />
          <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.firstName.invalid &&
              (signupForm.controls.firstName.dirty ||
                signupForm.controls.firstName.touched)
            "
          >
            First name is required.
          </div>
        </div>
        <!-- Second Name -->
        <div class="mb-6">
          <label for="" class="font-semibold">Middle Name</label>
          <input
            type="text"
            formControlName="middleName"
            placeholder="Enter Middle name"
            class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
          />
          <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.middleName.invalid &&
              (signupForm.controls.middleName.dirty ||
                signupForm.controls.middleName.touched)
            "
          >
            Second name is required.
          </div>
        </div>
        <!-- Third Name -->
        <div class="mb-6">
          <label for="" class="font-semibold">Last Name</label>
          <input
            type="text"
            formControlName="lastName"
            placeholder="Enter Last name"
            class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
          />
          <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.lastName.invalid &&
              (signupForm.controls.lastName.dirty ||
                signupForm.controls.lastName.touched)
            "
          >
            Last name is required.
          </div>
        </div>
        <!-- Email -->
        <div class="mb-6">
          <label for="" class="font-semibold">Email</label>
          <input
            type="email"
            formControlName="email"
            placeholder="you@example.com"
            class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
          />
          <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.email.hasError('required') &&
              (signupForm.controls.email.dirty ||
                signupForm.controls.email.touched)
            "
          >
            Email is required.
          </div>
          <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.email.hasError('email') &&
              signupForm.controls.email.touched
            "
          >
            Email is invalid.
          </div>
        </div>
        <!-- Country -->
        <!-- <div class="mb-6">
          <label for="" class="font-semibold">Country</label>
          <select
            class="border bg-white p-4 w-full rounded-xl font-bold appearance-nonse outline-none mt-4"
          >
            <option disabled selected>Kenya</option>
            <option>Uganda</option>
            <option>Tanzania</option>
          </select>
        </div> -->

        <!-- Number -->
        <div class="mb-6">
          <label for="" class="font-semibold">Phone Number</label>
          <input
            type="text"
            formControlName="phoneNumber"
            placeholder="+254 111 444 333"
            class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
          />
          <!-- <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.phoneNumber.invalid &&
              (signupForm.controls.phoneNumber.dirty ||
                signupForm.controls.phoneNumber.touched)
            "
          >
            Phone number is required.
          </div> -->
          <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.phoneNumber.hasError('required') &&
              signupForm.controls.phoneNumber.touched
            "
          >
            Phone number is required.
          </div>
          <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.phoneNumber.hasError('invalidPhone') &&
              signupForm.controls.phoneNumber.touched
            "
          >
            Invalid phone number. The phone number should start with +254
            followed by 9 digits or 07 followed by 8 digits.
          </div>
        </div>

        <!-- password -->
        <div class="mb-6">
          <label for="" class="font-semibold flex items-center justify-between"
            >Password
            <span
              class="underline hover:cursor-pointer"
              (click)="show = !show"
              >{{ show ? "hide" : "show" }}</span
            ></label
          >
          <input
            [type]="show ? 'text' : 'password'"
            formControlName="password"
            required="border border-red-300"
            placeholder="Please enter your password"
            class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
          />
          <div
            class="text-red-600 mt-1"
            *ngIf="
              signupForm.controls.password.invalid &&
              (signupForm.controls.password.dirty ||
                signupForm.controls.password.touched)
            "
          >
            Password is required.
          </div>
        </div>

        <!-- Confirm password -->
        <!-- <div class="mb-6">
          <label for="" class="font-semibold flex items-center justify-between"
            >Confirm Password
            <span
              class="underline hover:cursor-pointer"
              (click)="show = !show"
              >{{ show ? "hide" : "show" }}</span
            ></label
          >
          <input
            [type]="show ? 'text' : 'password'"
            name="Cpassword"
            placeholder="Please enter your password"
            class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
          />
        </div> -->
        <button
          [disabled]="signupForm.invalid"
          class="w-full py-4 rounded-full text-white bg-headerBlue mb-7"
        >
          Sign Up
        </button>

        <p class="text-center">
          By using Itqaan you agree to our
          <span class="underline hover:cursor-pointer"
            >Terms & Conditions & Privacy Policy</span
          >
        </p>
      </div>
    </form>

    <!-- login form -->
    <form
      *ngIf="!isSignUp"
      [formGroup]="loginForm"
      (ngSubmit)="loginUser()"
      class="bg-white rounded-lg mx-auto px-4 py-8 md:px-14 md:py-16 md:min-h-[700px]"
    >
      <p
        class="text-headerBlue text-3xl font-bold mb-10 text-center md:text-start"
      >
        Welcome to Itqaan
      </p>
      <!-- Email -->
      <div class="mb-6">
        <label for="" class="font-semibold">Email</label>
        <input
          type="email"
          formControlName="email"
          placeholder="you@example.com"
          class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
        />
        <div
          class="text-red-600 mt-1"
          *ngIf="
            loginForm.controls.email.hasError('required') &&
            (loginForm.controls.email.dirty || loginForm.controls.email.touched)
          "
        >
          Email is required.
        </div>
        <div
          class="text-red-600 mt-1"
          *ngIf="
            loginForm.controls.email.hasError('email') &&
            loginForm.controls.email.touched
          "
        >
          Email is invalid.
        </div>
      </div>
      <!-- password -->
      <div class="mb-6">
        <label for="" class="font-semibold flex items-center justify-between"
          >Password
          <span class="underline hover:cursor-pointer" (click)="show = !show">{{
            show ? "hide" : "show"
          }}</span></label
        >
        <input
          [type]="show ? 'text' : 'password'"
          formControlName="password"
          placeholder="Please enter your password"
          class="border border-black p-4 rounded-xl w-full font-bold appearance-none outline-none mt-4"
        />
        <div
          class="text-red-600 mt-1"
          *ngIf="
            loginForm.controls.password.invalid &&
            (loginForm.controls.password.dirty ||
              loginForm.controls.password.touched)
          "
        >
          Password is required.
        </div>
      </div>

      <button
        [disabled]="loginForm.invalid"
        class="w-full py-4 rounded-full text-white bg-headerBlue mb-7"
      >
        Sign In
      </button>
    </form>
  </div>
</div>
