import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconContainerComponent } from "./shared/components/icon-container/icon-container.component";
import { LoginComponent } from "./pages/auth/login/login.component";
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule, EntityDataService } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/service/auth.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthDataService } from './pages/auth/service/auth-data.service';
import { CashwaqfDataService } from './shared/store/cashwaqf/cashwaqf-data.service';
import { SadaqahDataService } from './shared/store/sadaqah/sadaqah-data.service';
import { HotToastModule } from '@ngneat/hot-toast';
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
       
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi:true
      }],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        IconContainerComponent,
        HttpClientModule,
        ReactiveFormsModule,
        HotToastModule.forRoot(),
        //// NGRX DATA Store init
        StoreModule.forRoot({}),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
        }),
        EffectsModule.forRoot([]),
        EntityDataModule.forRoot(entityConfig),
    ]
})
export class AppModule {
    constructor(
        entityDataService:EntityDataService,
        AuthDataService:AuthDataService,
        CashwaqfService:CashwaqfDataService,
        SadaqahService:SadaqahDataService,       

    ){
        entityDataService.registerServices({'Auth': AuthDataService})
        entityDataService.registerServices({'Cashwaqf': CashwaqfService})
        entityDataService.registerServices({'Sadaqah': SadaqahService})
    }
}
