import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './pages/auth/service/auth.service';
import { LoginComponent } from './pages/auth/login/login.component';
import { CashwaqfResolver } from './shared/state/cashwaqf.resolver';
import { SadaqahResolver } from './shared/state/sadaqah.resolver';

const routes: Routes = [
  {path: 'auth', component: LoginComponent},
  {
    path:'',
    resolve:{
      cashwaqfs: CashwaqfResolver,
      sadaqahs:SadaqahResolver
    },
    loadComponent:() => import('./pages/home/home.component').then(mod=> mod.HomeComponent)
  },
  {
    path:'about',
    loadComponent:() => import('./pages/about/about.component').then(mod=> mod.AboutComponent)
  },
  {
    path:'services',
    resolve:{
      cashwaqfs: CashwaqfResolver,
      sadaqahs:SadaqahResolver
    },
    loadChildren:() => import('./pages/service/services.routes').then(mod =>mod.SERVICES_ROUTES)
  },
  {
    path:'contact-us',
    loadComponent:() => import('./pages/contact-us/contact-us.component').then(mod=> mod.ContactUsComponent)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
