import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'icon-container',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './icon-container.component.html',
  styleUrls: ['./icon-container.component.scss']
})
export class IconContainerComponent {
  @Input() title:string = ''
  @Input() route:string = ''
  @Input() svgPath: string =''
  @Input() isUtility: boolean =false

}
