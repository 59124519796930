import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, tap } from "rxjs";
import { SadaqahEntityService } from "../store/sadaqah/sadaqah-entity.service";


@Injectable({
    providedIn: "root"
})
export class SadaqahResolver implements Resolve<boolean> {
    constructor(
        private sadaqah: SadaqahEntityService
    ){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.sadaqah.loaded$.pipe(
            tap(loaded => {
                if (!loaded) {
                    this.sadaqah.getAll();
                }
            })
        )
    }
}