<div
  [routerLink]="route === '' ? null : route"
  [ngClass]="{ 'bg-white': isUtility }"
  class="rounded-[20px] bg-slate-100 w-[178px] md:w-[361px] h-[183px] px-[11px] py-[23px] flex flex-col justify-between transition-all duration-300 group hover:bg-headerBlue"
>
  <ng-container *ngIf="isUtility; else notUtility">
    <img
      [src]="svgPath"
      alt="Cash waqf icon"
      class="w-[90px] h-[92px] transition-all duration-300"
    />
  </ng-container>

  <ng-template #notUtility>
    <div
      class="bg-iconBg w-16 h-16 md:w-[90px] md:h-[90px] rounded-full flex items-center justify-center transition-all duration-300 group-hover:bg-blue-600"
    >
      <img
        [src]="svgPath"
        alt="Cash waqf icon"
        class="w-10 h-10 transition-all duration-300 filter group-hover:brightness-0 group-hover:invert"
      />
    </div>
  </ng-template>

  <div class="flex items-center justify-between">
    <p
      class="text-headerBlue font-bold text-md md:text-2xl transition-all duration-300 group-hover:text-white"
    >
      {{ title }}
    </p>
    <div
      class="bg-headerBlue rounded-full w-6 h-6 flex items-center justify-center transition-all duration-300 group-hover:bg-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="transition-all duration-300 fill-white group-hover:fill-blue-700"
      >
        <path
          d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
        ></path>
      </svg>
    </div>
  </div>
</div>
