import { Component, EventEmitter, Output } from "@angular/core";
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { AuthEntityService } from "../service/auth-entity.service";
import { AuthService } from "../service/auth.service";
import { HotToastService } from '@ngneat/hot-toast';
@Component({
  selector: 'app-login',
  
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  isSignUp:boolean = false;
  show:boolean = false;

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(4)]]
  });

  signupForm = this.fb.group({
    firstName:['', [Validators.required]],
    middleName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: ['', [Validators.required, Validators.minLength(10),this.phoneValidator]],
    password: ['', [Validators.required, Validators.minLength(4)]],
  })

  constructor(
    private fb: FormBuilder,
    private authService: AuthEntityService,
    private signupService: AuthService,
    private toastService: HotToastService

  ) {
  }

  phoneValidator(control: AbstractControl): { [key: string]: any } | null {
    // Regular expression to match +254 followed by 9 digits or 07 followed by 8 digits.
    const phonePattern = /^(?:\+254|07)\d{8}$/;
    const isValid =phonePattern.test(control.value) ? null : { invalidPhone: true };    
    return isValid
  }
  
  // login user
  loginUser() {
    const loginData = {
      ...this.loginForm.value
    }
    this.authService.add(loginData).subscribe({
      next: (res) => { 
        console.log('sd', res);
        if(res.status === 'Success') {
          this.toastService.success('Welcome!')
        } else {
          const message = `Something went wrong, ${res.message}`
          this.toastService.error(message)
        }
      },
      error:(err) => { 
        console.log('Login Data Err', err);
      }
    });
  }

  // signup User
  signupUser() {
    const signupData = {
      ...this.signupForm.value
    }
    this.signupService.signupUser(signupData).subscribe({
      next: (res) => { 
      },
      error:(err) => { console.log('Signup Data Err', err);
      }
    });

  }


}
