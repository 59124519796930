import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {LocalService} from "./local.service";

import {AuthDataService} from "../../pages/auth/service/auth-data.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private localStore: LocalService,
    private authService: AuthDataService

  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const refreshToken = this.localStore.getData('itqrtk');
    const token = this.localStore.getData('itqtk');

    if (token) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            //// Run auto login
           
          }
        }
        return throwError(err)
      })
    );
  }
}